/*
    Taken from: https://www.joshwcomeau.com/css/custom-css-reset/
*/
/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

/*
  2. Remove default margin
*/
* {
  margin: 0;
  padding: 0;
}

/*
  3. Allow percentage-based heights in the application
*/
html,
body {
  height: 100%;
}

/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
}

/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}

/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/*
    Taken from: https://andy-bell.co.uk/a-modern-css-reset/
*/
/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
/*
    Taken from: https://meyerweb.com/eric/tools/css/reset/
*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.compliance {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border: 1px solid #363a58;
}
.compliance h3 {
  grid-column: 1/-1;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  background-color: #363a58;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 0;
}
.compliance div {
  border: 1px solid #363a58;
}
.compliance p {
  margin: 0 auto;
  padding: 0;
  font-size: 12px;
  color: #363a58;
  text-align: center;
  width: 60%;
}
.compliance p:first-of-type {
  color: #9e9e9e;
  font-weight: bold;
}
.compliance p:last-of-type {
  font-size: 24px;
  font-weight: bold;
  color: #363a58;
}
.compliance p.important {
  color: #bb5233;
}
body {
  padding: 4rem;
}

main {
  width: 100%;
  display: flex;
  flex-direction: column;
}
main h1 {
  margin-bottom: 1rem;
}
main h2 {
  margin-top: 5rem;
  margin-bottom: 1rem;
}
main h2:first-of-type {
  margin-top: 0;
}
main h3 {
  margin-bottom: 1rem;
}
main hr {
  margin-bottom: 50px;
}
main div.container {
  position: relative;
  margin-top: 100px;
  margin-bottom: 100px;
}
main div.container:first-of-type {
  margin-top: 20px;
}
main div.container .fleet {
  position: absolute;
  top: 0;
  right: 0;
}

.small_multiples {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 35vh));
  -moz-column-gap: 4rem;
       column-gap: 4rem;
  justify-content: center;
}

.paired {
  display: flex;
  flex-direction: row;
  width: 100%;
  -moz-column-gap: 3rem;
       column-gap: 3rem;
  margin: 0 auto;
  margin-bottom: 3rem;
}
.paired .scatterplot {
  width: 60%;
}
.paired .radar {
  width: 30%;
}
.layout__horizontal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.layout__horizontal .wrapper {
  width: 65%;
}
.layout__horizontal .compliance {
  width: 35%;
}
.scatter {
  cursor: pointer;
}

:root {
  color: black;
}
:root {
  font-family: sans-serif;
}

main h1,
main h2,
main h3 {
  text-align: left;
  font-size: 42px;
  font-weight: 700;
  color: #363a58;
}
main h1 .size,
main h2 .size,
main h3 .size {
  font-size: 54px;
}
main h3.fleet {
  text-align: right;
  line-height: 1em;
}
main p {
  color: #363a58;
  font-size: 16px;
  margin-left: 75px;
  margin-top: 30px;
}
main p.footnote {
  font-size: 12px;
}
main hr {
  width: 70%;
  border: 1px solid #363A58;
}

.small_multiples h3 {
  text-align: center;
  margin-top: 2rem;
}
.small_multiples .wrapper {
  margin-bottom: 2rem;
}
/*# sourceMappingURL=style.css.map */
